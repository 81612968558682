<template>
  <div class="question-content" v-if="questionList && questionList.length > 0">
    <h4>其他人都在问</h4>
    <ul class="ask-list" ref="askList">
      <li class="ask-item" v-for="(item, index) in questionList" :key="'question_ask_'+index">
        <div class="ask-left">
          <p class="ask-user">{{ item.name }}</p>
          <p class="user-question">
            {{ item.question }}
          </p>
        </div>
        <div class="ask-right">
          <button
            class="same-ask"
            :class="item.isClick ? 'selected' : ''"
            v-if="item.isClick"
          >
            已同问
          </button>
          <button
            class="same-ask"
            v-else
            @click="sameQuestion(item.questionId)"
          >
            {{ item.nums }} 同问
          </button>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    courseId: Number,
    questionList: Array,
  },
  data() {
    return {
      token: null,
    };
  },
  mounted() {
    this.token = window.localStorage.getItem("token");
  },
  watch: {
    questionList() {
      if (this.questionList.length > 0) {
        this.$nextTick(() => {
          const height = this.$refs.askList.scrollHeight;
          this.$refs.askList.scrollTo(0, height);
        });
      }
    },
  },
  methods: {
    // 同问
    sameQuestion(questionId) {
      const url = `https://www.msup.com.cn/wechat/small-program/same-question?questionId=${questionId}&token=${this.token}`;
      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          console.log(res);
          if (res.success === true) {
            this.$message.success("同问成功");
            this.$emit("updateQuestionList");
          } else {
            console.log("同问失败");
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 767px) {
  .question-content {
    background-color: #fff;
    padding: 30px 22px 49px;
    h4 {
      color: #333333;
      font-size: 19px;
      font-weight: 400;
    }
    .ask-list {
      margin-top: 16px;
      max-height: 170px;
      // max-height: 267px;
      padding-bottom: 9px;
      display: flex;
      flex-direction: column;
      overflow: auto;
      background: rgba(243, 244, 246, 0.5);
      .ask-item {
        flex-grow: 1;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px 25px 20px 30px;
        // padding: 22px 28px 24px 37px;
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 30px;
          right: 25px;
          // left: 37px;
          // right: 28px;
          width: auto;
          height: 1px;
          background: #dddddd;
        }
        &:last-child::after {
          display: none;
        }
        .ask-left {
          max-width: 420px;
          .ask-user {
            color: #2e65a8;
            font-size: 14px;
          }
          .user-question {
            margin-top: 12px;
            line-height: 21px;
            font-size: 14px;
            color: #999999;
          }
        }
        .ask-right {
          .same-ask {
            border: none;
            outline: none;
            width: 74px;
            height: 32px;
            background: rgba(46, 101, 168, 0.15);
            border-radius: 4px;
            color: #0a70c5;
            font-size: 17px;
            letter-spacing: 1px;
            cursor: pointer;
            &.selected {
              background: #0a70c5;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .question-content {
    background-color: #fff;
    padding: 21px 0 16.5px;
    h4 {
      color: #050505;
      font-size: 15px;
      font-weight: 400;
    }
    .ask-list {
      margin-top: 15.5px;
      // max-height: 267px;
      padding-bottom: 9px;
      display: flex;
      flex-direction: column;
      // overflow: auto;
      background: #f3f4f680;
      .ask-item {
        flex-grow: 1;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16.5px 4.4vw 12px 5vw;
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: 5vw;
          right: 4.4vw;
          width: auto;
          height: 1px;
          background: #dddddd;
        }
        &:last-child::after {
          display: none;
        }
        .ask-left {
          max-width: 50vw;
          .ask-user {
            color: #2e65a8;
            font-size: 13px;
          }
          .user-question {
            margin-top: 6.5px;
            line-height: 16px;
            font-size: 12px;
            color: #999999;
            text-align: justify;
          }
        }
        .ask-right {
          .same-ask {
            border: none;
            outline: none;
            width: 12.5vw;
            height: 6vw;
            background: rgba(46, 101, 168, 0.15);
            border-radius: 4px;
            color: #0a70c5;
            font-size: 12px;
            letter-spacing: 1px;
            cursor: pointer;
            &.selected {
              background: #0a70c5;
              color: #fff;
            }
          }
        }
      }
    }
  }
}
</style>
