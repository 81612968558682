<template>
  <div class="question-list-wrapper" ref="askListContent">
    <div class="course-list-content" v-if="selectedCourseId === 0">
      <h4 class="course-list-title">请选择您要提问的课程</h4>
      <ul class="course-list" v-if="detail[selectedTime]">
        <li v-for="(item, index) in detail[selectedTime].all" :key="'question_detail_0_'+index">
          <div
            class="case-title"
            v-if="item[groupId]"
            @click="selectCourse(item[groupId])"
            :data-id="item[groupId].courseId"
          >
            <img :src="item[groupId].lecturers[0].thumbs" class="photo" />
            <div class="lecturer">
              <div class="time">{{ index }}</div>
              <h3 class="title">{{ item[groupId].courseTitle }}</h3>
              <div
                class="teacher"
                v-for="(lecturer, i) in item[groupId].lecturers"
                :key="i"
              >
                {{ lecturer.name }} | {{ lecturer.company }}
                {{ lecturer.position }}
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="course-list-content" v-if="selectedCourseId !== 0">
      <div class="ask-question">
        <h4 class="course-list-title">提问</h4>
        <div class="course-item">
          课程：
          <el-dropdown
            trigger="click"
            v-if="detail[selectedTime]"
            @command="selectCourse"
          >
            <span class="el-dropdown-link">
              <span class="title-text">{{ selectedCourseTitle }}</span
              ><i class="el-icon-arrow-down el-icon-caret-bottom"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-for="(item, index) in Object.values(detail[selectedTime].all)"
                :class="item[groupId] ? 'add-border' : ''"
                :key="'question_detail_'+index"
                :value="item[groupId] && item[groupId].courseTitle"
                :command="item[groupId]"
              >
                {{
                  item[groupId] && item[groupId].courseTitle
                }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <div class="course-item">
          提问：
          <a-textarea class="feedback-input" :rows="4" v-model="question" />
        </div>
        <div class="course-item">
          <button class="ask-btn" @click="askQuestion">提问</button>
        </div>
      </div>
      <QuestionList
        :courseId="selectedCourseId"
        :questionList="questionList"
      ></QuestionList>
    </div>
  </div>
</template>

<script>
import QuestionList from "./QuestionList.vue";
import {sid} from "@/utils/api.js";

export default {
  components: { QuestionList },
  props: {
    detail: Object,
    selectedTime: String,
    token: String,
  },
  mounted() {
    this.groupId = this.$route.query.groupid;
    this.getQuestionList();
  },
  data() {
    return {
      groupId: "",
      selectedCourseTitle: "",
      question: "",
      selectedCourseId: 0,
      questionList: [],
    };
  },
  methods: {
    selectCourse(courseItem) {
      this.selectedCourseId = courseItem.courseId;
      this.selectedCourseTitle = courseItem.courseTitle;
      this.getQuestionList();
    },
    // 提问
    askQuestion() {
      if (this.question.trim() === "") {
        this.$message.error("问题不能为空");
        return false;
      }
      const url = `https://www.msup.com.cn/wechat/small-program/ask-questions?sid=${sid(this.global.year)}&courseId=${this.selectedCourseId}&token=${this.token}&question=${this.question}`;
      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          console.log(res);
          if (res.success === true) {
            this.$message.success("提问成功");
            this.question = "";
            this.getQuestionList();
          } else {
            console.log("提问失败");
          }
        });
    },
    //  问题列表
    getQuestionList() {
      const url = `https://www.msup.com.cn/wechat/small-program/course-and-ppt?sid=${sid(this.global.year)}&cid=${this.selectedCourseId}&token=${this.token}`;
      this.$http
        .jsonp(url)
        .then((data) => {
          return data.json();
        })
        .then((res) => {
          // console.log(res);
          if (res.success === true) {
            this.questionList = res.data.question;
          } else {
            console.log("获取问题列表失败");
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.el-dropdown{
  text-align: left;
}
.el-dropdown-link {
  width: 66vw;
  height: 30px;
  border: 1px solid #d6dfea;
  position: relative;
  display: inline-block;
  font-size: 12px;
  font-weight: 600;
  .title-text {
    width: 59vw;
    padding: 6px 3.2vw;
    line-height: 16px;
    height: 24px;
    cursor: pointer;
    color: #000000;
    display: inline-block;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    overflow: hidden;
    font-size: 12px;
    font-weight: 500;
  }
}
.el-icon-arrow-down {
  position: absolute;
  right: 2vw;
  top: 50%;
  transform: translateY(-50%);
  font-size: 24px;
}
.el-dropdown-menu {
  width: 66vw;
}
.el-dropdown-menu__item.add-border {
  border-bottom: 1px solid #dddddd;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  overflow: hidden;
  font-size: 12px;
  font-weight: 500;
}
.question-list-wrapper {
  background: #fff;
}
// 选择课程进行提问
.course-list {
  li {
    margin-top: 13px;
    width: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    .case-title {
      border-radius: 10px;
      padding: 9px 4vw 10px 4vw;
      display: flex;
      cursor: pointer;
      background: #ffffff;
      .photo {
        width: 16vw;
        height: 16vw;
        background: #6c7585;
        border-radius: 5px;
      }
      .lecturer {
        flex: 1;
        margin-left: 25px;
        letter-spacing: 1px;
        .time {
          margin-bottom: 4px;
          color: #909090;
          font-size: 12px;
          text-align: left;
          font-weight: 400;
        }
        .title {
          margin: 0;
          padding: 0;
          color: #000000;
          font-size: 14px;
          font-weight: 500;
          text-align: left;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          box-orient: vertical;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .teacher {
          margin-top: 6px;
          margin-bottom: 4.5px;
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          text-align: left;
          color: #909090;
          display: flex;
          flex-wrap: wrap;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          box-orient: vertical;
          -webkit-line-clamp: 1;
          line-clamp: 1;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
.course-list-content {
  padding: 24.5px 4.6vw 0 4.2vw;
  .ask-question {
    .course-list-title {
      margin-bottom: 16.5px;
      font-size: 15px;
      text-align: center;
      color: #050505;
    }
    .course-item {
      color: #000000;
      font-size: 12px;
      font-weight: 600;
      display: flex;
      justify-content: space-between;
      padding-bottom: 9px;
      &:nth-child(2) {
        align-items: center;
      }
      &:nth-child(3) {
        align-items: flex-start;
      }
      &:last-child {
        border-bottom: 1px solid #dddddd;
        justify-content: flex-end;
        padding-bottom: 14.5px;
      }
      .feedback-input {
        border-radius: 0;
        max-width: 66vw;
        font-size: 12px;
        line-height: 16px;
      }
      .ask-btn {
        border: none;
        outline: none;
        background: #0a70c5;
        border-radius: 3px;
        width: 14.4vw;
        height: 7.2vw;
        color: #fff;
      }
    }
  }
  .course-list-title{
    text-align: left;
  }
}
</style>
